module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-114305695-2","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Form ráðgjöf","short_name":"Form","start_url":"/","display":"minimal-ui","icon":"src/images/form_logo_bkg.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7787060f6ae534a10f91aff9cfe58a46"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
